import { FirebaseApp, initializeApp as _initializeApp } from 'firebase/app';
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged as _onAuthStateChanged,
  User,
  Unsubscribe,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

// Initialize

const firebaseConfig = {
  apiKey: 'AIzaSyCJ0G7DH9lLzKVIpNpO0uC1KfP4jL_6G5w',
  authDomain: 'ymcloud-jp-website.firebaseapp.com',
  databaseURL: 'https://ymcloud-jp-website.firebaseio.com',
  projectId: 'ymcloud-jp-website',
  storageBucket: 'ymcloud-jp-website.appspot.com',
  messagingSenderId: '618016064615',
  appId: '1:618016064615:web:2268faa844f8f52b3b40c2',
};

export const initializeApp = (): FirebaseApp => _initializeApp(firebaseConfig);

// Auth

export const signIn = async (): Promise<null> => {
  const auth = getAuth();
  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.error(error);
  }
  return null;
};
export const onAuthStateChanged = (
  observer: (user: User) => void
): Unsubscribe => {
  const auth = getAuth();
  return _onAuthStateChanged(auth, observer);
};

// Firestore

type AddContactPayload = {
  name: string;
  email: string;
  message: string;
  createdBy: string;
};
export const addContact = async (payload: AddContactPayload) => {
  const db = getFirestore();
  await addDoc(collection(db, 'sites/ymcloud-jp-blog/contacts'), {
    ...payload,
    createdAt: serverTimestamp(),
  });
};
