// import React from 'react';
// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// custom font
import '@fontsource/noto-sans-jp/japanese-500.css';
import '@fontsource/noto-serif-jp/japanese-500.css';
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"
import './src/index.css';
import './src/markdown.css';

// Highlighting for code blocks
import 'prismjs/themes/prism.css';
import { initializeApp } from './src/plugins/firebase';

export const onClientEntry = () => {
  initializeApp();
};
